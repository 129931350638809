<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{ this.$t('_common.How_can_I_use_this_form') }}</v-card-title>

          <v-card-text>{{ this.$t('_glossary._info.content') }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{ this.$t('_common.OK') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->


      <v-container class="m-0 py-0 col-12">
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-card width="100%">
              <v-sheet class="px-4 pt-2">
                <v-text-field
                  v-model="search"
                  :label="this.$t('_glossary.Search_Topic')"
                  flat
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  class="mt-0"
                ></v-text-field>
              </v-sheet>
              <v-card-text>
                <v-treeview
                  :items="treeTopicData"
                  :search="search"
                  v-model="treeTopicDataModel"
                  item-children="Children"
                  activatable
                  multiple="false"
                  selection-type="leaf"
                  item-key="Id"
                  color="accent"
                  item-text="Title"
                  @update:active="onSelectedTopic"
                  style="min-width: fit-content; height:416px; overflow:auto;"
                ></v-treeview>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card width="100%">
              <v-carousel
                class="animated flipInX animated"
                style="width: 100%;"
                show-arrows-on-hover
                hide-delimiters
                v-model="cardStep"
              >
                <v-carousel-item v-for="(searchResult,i) in indexedItems" :key="i">
                  <vue-flashcard
                    style="height: 100%"
                    class="center-flashcard"
                    text-size-front="5em"
                    text-size-back="5em"
                    :front="searchResult.Key"
                    :back="searchResult.Value"
                    :header-front="$t('_glossary.Termcard_header_front')"
                    :header-back="$t('_glossary.Termcard_header_back')"
                    :footer-front="$t('_glossary.Termcard_footer_front')"
                    :footer-back="$t('_glossary.Termcard_footer_back')"
                  ></vue-flashcard>
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-card xs12 width="100%">
              <v-sheet class="px-4 pt-2">
                <v-text-field
                  v-model="input2"
                  v-model.lazy="searchWord"
                  :append-outer-icon="'search'"
                  @click:append-outer="searchTxt"
                  :label="this.$t('_glossary.Search_Word')"
                  flat
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  v-on:keyup.enter="searchTxt()"
                ></v-text-field>
              </v-sheet>
              <v-data-table
                :headers="headers"
                :items="indexedItems"
                :server-items-length="totalItems"
                :options.sync="options"
                :page.sync="options.pagination.page"
                :items-per-page.sync="options.pagination.itemsPerPage"
                :loading="tableLoading"
                class="elevation-1"
                :footer-props="{
                'items-per-page-text':this.$t('_common.Rows_per_page')
                }"
                :no-data-text="this.$t('_common.No_data_available')"
                @update:page="updatePage"
                @update:items-per-page="updatePage"
                @click:row="rowClick"
                height=384
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td>{{props.item.Key}}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
</template>

<script>
  import VueFlashcard from "vue-flashcard";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";

  require("../../assets/css/neo.css");

  export default {
    data() {
      return {
        searchWord: "",
        take: 0,
        skip: 0,
        topic: 0,
        parentId: 0,
        frontTxt: "This is the front",
        backTxt: "This is the back",
        visible: false,
        searchResults: [],
        headers: [
          {
            text: this.$t('_glossary.Words'),
            align: "left",
            sortable: false,
            value: "Key"
          }
        ],

        cardStep: 0,
        treeTopicData: [],
        treeTopicDataModel: [],
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.2
        },
        options: {
          pagination: {
            page: 1, // skip
            itemsPerPage: 10, //take
            pageCount: 0,
            footerProps: {"items-per-page-options": [5, 10, 15, 20]}
          }
        },

        tableLoading: false,
        totalItems: 0,

        search: null,
        card_header_props: {
          header: {
            headLine: this.$t('_glossary.Glossary'),
            subTitle: this.$t('_glossary.View_flashcards'),
            icon: "flip"
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false
          },
        }
      };
    },

    methods: {
      searchTxt() {
        if (
          (this.searchWord !== "" && this.searchWord.length >= 3) ||
          this.topic > 0
        ) {
          this.skip = this.options.pagination.page - 1;
          this.take = this.options.pagination.itemsPerPage;

          var searchBody = {
            Text: this.searchWord,
            Take: this.take,
            Skip: this.skip,
            Topic: this.topic
          };
          let me = this;
          this.$goc.request.post(
            this.$enums.API.SearchTerm,
            searchBody,
            response => {
              if (response.Result === undefined) {
                this.visible = false;
                return;
              }

              if (response.Result.Terms.length > 0) {
                this.totalItems = response.Result.TotalItemCount;
                this.searchResults = response.Result.Terms;
                this.visible = true;
              } else {
                this.searchResults = [];
                this.visible = false;
                me.$goc.notify.info({
                  title: this.$t("_common.No_Data"),
                  message: this.$t("_glossary.Term_was_not_found")
                });
              }
            }
          );
        }
      },
      rowClick(selectedItem) {
        this.cardStep = selectedItem.index - 1;
      },
      dialogNotify(title, message, notifyColor) {
        this.$vs.notify({
          title: title,
          text: message,
          color: notifyColor
        });
      },

      validate() {
        return this.$refs.form.validate() ? true : false;
      },
      getTopics() {
        let body = {
          Id : JSON.parse(localStorage.getItem("userInfo")).Uid
        };
        this.$goc.request.post(this.$enums.API.AssignedTopic, body, response => {
          this.treeTopicData = response.Result.Topics;
        });
      },
      onSelectedTopic(item) {
        // this.selected = this.treeTopicData[item[0]-1].id;
        this.selected = item[0];
        this.treeTopicDataModel = item;
        this.searchWord = "";
        this.skip = 0;
        this.take = 0;
        this.topic = item[0];
        this.searchTxt();
      },
      updatePage(page) {
        this.$goc.console.log(page);
        this.$goc.console.log(this.options);
        this.searchTxt();
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.searchWord != "";
      },
      indexedItems() {
        return this.searchResults.map((item, index) => ({
          index: index + 1,
          ...item
        }));
      }
    },
    mounted: function () {
      this.getTopics();
    },

    components: {
      VueFlashcard,
      VuePerfectScrollbar
    }
  };
</script>

<style>
  .animated.flipInX.animated {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    margin: 0;
    border-radius: 7px;
  }

  .animated.flipInX.animated:hover {
    box-shadow: 1px 4px 25px 7px rgba(0, 0, 0, 0.4);
    margin: 0;
    border-radius: 7px;
  }

  .animated.flipInX.flashcard {
    height: 100%;
  }

  .card-header {
    margin-top: 17%;
  }

  .v-data-table.elevation-1.theme--light:hover {
    cursor: pointer;
  }
</style>
